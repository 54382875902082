import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import /* Hero, */ { HeroInner } from "../components/hero"
import Image from "../components/Image"
import getMetatags from "../util/getMetatags"
import Seo from "../components/Seo"
import LinkedImg from "../images/linkedin-leaders.svg"

function MeetPage({ data: { nodeMeet } }) {

  //console.log(nodeOverview)

  const { leadershipTitle, investorsTitle, relationships, showCTA } = nodeMeet
  const { hero, introduction, leadership, investors, single } = relationships
  const seo = getMetatags(nodeMeet.metatag, nodeMeet.relationships.OGImage?.localFile?.publicURL)

  return (
    <Layout showCta={showCTA} /* headerBg="bg-background-gray" dark */>
      <Seo {...seo} />
      <HeroInner {...hero} bgColor={'bg-white'} noImage={true} />
      <Introduction items={introduction} />
      <Leadership title={leadershipTitle} items={leadership} />
      <Investors title={investorsTitle} items={investors} />
      <JoinUs {...single} />
    </Layout >
  )
}


export default MeetPage



const Introduction = ({ items }) => {

  //console.log(items)
  return (
    <section className="space-y-9 lg:space-y-14 p-5 lg:p-0">
      {items.map((item, index) => (

        <div className={"container lg:max-w-7xl	border p-5 lg:p-14 flex  lg:flex-row lg:space-x-14 " + (index % 2 === 0 ? ' flex-col ' : ' flex-col-reverse ')} key={index}>
          {index % 2 === 0 && <>
            <div className="lg:max-w-[500px]">
              <div className="Subtitle-M-Bold pb-6 lg:pb-4">{item.title}</div>
              <div className="Body-M-Light pb-6 lg:pb-0">{item.description}</div>

            </div>

            <div className="lg:grow w-full">
              <Image image={item.relationships.image} alt={item.image.alt} className="w-full block" />
            </div>
          </>
          }
          {index % 2 !== 0 && <>
            <div className="lg:grow lg:w-full">
              <Image image={item.relationships.image} alt={item.image.alt} className="w-full block" />
            </div>
            <div className="lg:max-w-[500px] ">
              <div className="Subtitle-M-Bold pb-6 lg:pb-4">{item.title}</div>
              <div className="Body-M-Light pb-6 lg:pb-0">{item.description}</div>

            </div>


          </>
          }
        </div>

      ))}
    </section>
  )
}


const Leadership = ({ title, items }) => {

  //const rows = [
    //[items[0], items[1]],
    //[items[2], items[3], items[4]]
  //]

  return (
    <section >
      <div className="container py-9 lg:py-24">

        <h2 className="H2 pb-2 lg:pb-24">{title}</h2>

        <div className="lg:space-y-28 ">
          
            <div className="flex flex-col  lg:flex-row lg:justify-evenly my-3 lg:my-0 " >
              {items.map((item, index) => (
                <LeaderCard {...item} index={index} key={index} />
              ))}
            </div>

        </div>
      </div>
    </section>
  )
}

const LeaderCard = ({ title, subtitle, description, linkedIn, relationships, image, index, ...props }) => {

  const [show, setShow] = useState(false)

  /* const toggle = () => {
    setShow(!show)
  } */


  return (

    <div className="border-2 border-grey w-full lg:w-[316px] my-8 lg:my-0" key={index}>
      <div className="m-8 relative overflow-hidden	" onMouseEnter={() => { setShow(true) }} onMouseLeave={() => { setShow(false) }} role="textbox" tabIndex={'showBio' + index}>
        <Image image={relationships?.image} alt={image?.alt} className="lg:w-[246px] lg:h-[246px]" />
        <p className={'bg-blue p-3 text-white absolute duration-700 w-full text-xs h-[246px]  hidden lg:block ' + (show ? '   top-0 block' : '  top-[270px] 	')}>{description}</p>

      </div>
      <div className="text-white relative w-full lg:w-[314px]  h-[108px]">
        <div className="bg-blue px-4 py-3 absolute w-full lg:w-[314px] h-[108px] left-3 top-3 ">
          <div className="Subtitle-M-Bold">{title}</div>
          <div className="Body-M-Light">
            {subtitle}
            {linkedIn?.uri && <a href={linkedIn.uri} target="__blank" className="z-10"><img src={LinkedImg} alt="LinkedIn Profile" className="pr-8 float-right inline" loading="lazy" /> </a>}
          </div>
        </div>
      </div>
    </div >

  )
}



const Investors = ({ title, items }) => {

  return (
    <section>
      <div className="container pt-0 pb-8 lg:py-24 ">

        <h2 className="H2 pb-10 lg:pb-24">{title}</h2>

        <div className="flex flex-wrap justify-evenly ">
          {items.map((e, i) => (
            <div key={i} className=" w-1/2  lg:h-auto lg:w-auto  pb-6 px-6 lg:p-0 ">
              <Image image={e.relationships.image} alt={e.image.alt} className="" />
            </div>
          ))}
        </div>
      </div>

    </section>
  )
}


const JoinUs = ({ title, description, cta, relationships, image, ...props }) => {


  return (
    <section className="p-3 lg:p-0">
      <div className="container lg:max-w-7xl border-2 border-grey p-7 lg:p-14 space-y-7 lg:space-y-14">
        <h2 className="H2">{title}</h2>
        <div className="flex flex-col lg:flex-row lg:space-x-14 w-full">
          <div>
            <Image image={relationships.image} alt={image.alt} />
          </div>
          <div className=" grow flex lg:items-center pt-7 lg:pt-0">
            <div className="space-y-6 w-full">
              <div className="Subtitle-M-Bold text-center lg:max-w-lg mx-auto" dangerouslySetInnerHTML={{ __html: description }} />

              <div className="text-normal bg-blue text-white mx-auto p-3 rounded-full w-full lg:w-64 font-semibold text-center	">
                <Link to={cta.uri}>{cta.title}</Link>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
  )
}


export const query = graphql`
  query meet($id: String!) {
    nodeMeet(id: { eq: $id }) {
      id
      title
      leadershipTitle : field_title
      investorsTitle : field_title2
      showCTA: field_show_book_demo
      metatag {
        attributes {
          content
          name
        }
        tag
      }
      path{
        alias
      }
      relationships {
        hero: field_hero {
          ...HeroContent
        }
        introduction: field_paragraph_repeter {
          ...ParagraphGenericBlock
        }
        leadership: field_items {
          ...ParagraphImageAndText
        }
        investors: field_paragraph_repeater2 {
          image: field_image {
            title
            alt
          }
          paragraph_type {
            drupal_internal__target_id
          }
          relationships {
            image: field_image {
              uri {
                url
              }
              localFile {
                url
                publicURL
              }
            }
          }
        }
        single: field_paragraph_single{
          ...ParagraphGenericBlock
        }
      }
    }
  }
`
